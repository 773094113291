<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="text-h5 rm-dialog-title"
        >Добавить пользователя к воронке</v-card-title
      >
      <v-card-text class="black--text">
        <div>
          <div class="mb-1"><strong>Пользователь</strong></div>
          <option-row title="ID:">{{ talentId }}</option-row>
          <option-row title="ФИО:">{{ fullName }}</option-row>
          <option-row title="Email:">{{
            selectedUser && selectedUser.email
          }}</option-row>
          <div class="mt-2">
            <v-chip
              v-for="item in tags"
              :key="item.id"
              :color="item.color"
              class="mr-2 mb-1"
              dark
              small
            >
              {{ item.name }}
            </v-chip>
          </div>
          <v-divider class="mb-3 mt-3"></v-divider>
        </div>
        <p>
          Выберите к какой воронке добавить пользователя. Он будет добавлен к
          списку ID пользователей этой воронки
        </p>
        <div>
          <v-autocomplete
            v-model="selectedBoard"
            :items="boardsOptons"
            :loading="searchPending"
            :search-input.sync="searchQuery"
            outlined
            dense
            clearable
            item-text="name"
            item-value="id"
            label="Название воронки"
            hint="Начните вводить название"
            persistent-hint
            return-object
          >
            <template #item="{ item }">
              <div>
                <div>{{ item.name }}</div>
                <div class="text-caption">{{ item.info }}</div>
              </div>
            </template>
          </v-autocomplete>
        </div>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn color="primary" :disabled="pending" @click="handleSubmit"
          >Добавить</v-btn
        >
        <v-btn color="primary" outlined @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiClient } from "@/api";
import debounce from "lodash/debounce";
import { MAX_BOARD_ID_LIST } from "@/constants";
import { getFullName } from "@/api/utils/dataParsers";
const initialState = () => {
  return {
    selectedBoard: null,
    pending: false,
    records: [],
    tags: [],
    boards: [],
    searchPending: false,
    searchQuery: "",
    size: 10,
    page: 1,
  };
};
export default {
  name: "AssignUserToBoard",
  props: {
    value: Boolean,
    /**
     * @prop
     * id пользователя
     */
    talentId: {
      type: Number,
    },
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    fullName() {
      return getFullName(this.selectedUser);
    },
    selectedUser() {
      return this.$store.state.talent.users[this.talentId];
    },
    userBoardsids() {
      return this.records?.map((n) => n.column.board_id) || [];
    },
    boardsOptons() {
      const { boards, userBoardsids } = this;
      if (!boards?.length) return [];
      return boards
        .map((n) => {
          const isAssigned = userBoardsids.includes(n.id);
          const isLimitReached = n.talent_ids?.length >= MAX_BOARD_ID_LIST;
          return {
            id: n.id,
            name: n.name,
            disabled: isAssigned || isLimitReached,
            talent_ids: n.talent_ids,
            info: isAssigned
              ? "Пользователь уже состоит в этой воронке"
              : isLimitReached
              ? `Достигнут лимит по длине списка ID пользователей (${MAX_BOARD_ID_LIST})`
              : "",
          };
        })
        .sort((a, b) => {
          return a.info < b.info ? -1 : 1;
        });
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val) {
          this.reset();
        } else {
          this.getUserData(this.talentId);
        }
      },
    },
    searchQuery: {
      handler() {
        this.debouncedSearch();
      },
    },
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 400);
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    handleClose() {
      this.$emit("input", false);
    },
    async getUserData(id) {
      const { selectedUser } = this;
      this.pending = true;
      if (!selectedUser) {
        try {
          await this.$store.dispatch("talent/getUserById", id);
        } catch (error) {
          this.error = `Не удалось получить данные о контакте в Таланте. ${error.message}`;
          this.pending = false;
          return;
        }
      }
      // Получаем данные о контакте
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/contacts/${id}`,
        });
        this.records = data.Records;
        this.tags = data.tags;
      } catch (error) {
        console.log("error", error);
        // ничего не делаем, если 404
      }
      this.pending = false;
    },
    async handleSearch() {
      try {
        this.searchPending = true;
        const { data } = await apiClient({
          method: "GET",
          url: "/boards",
          params: {
            offset: this.size * (this.page - 1),
            limit: this.size,
            search: this.searchQuery,
          },
        });
        this.boards = data.results;
      } catch (error) {
        this._showError(`Не удалось найти воронки. ${error.message}`);
      }
      this.searchPending = false;
    },
    async handleSubmit() {
      const { selectedBoard } = this;
      if (!selectedBoard) return;
      try {
        await apiClient({
          method: "POST",
          url: `/boards/${selectedBoard.id}/talent_ids`,
          data: {
            talent_ids: [this.talentId],
          },
        });
        this.$toast(`Контакт добавлен к воронке`, { type: "success" });
        // шлем задачу на синк
        apiClient({
          method: "POST",
          url: `/boards/${selectedBoard.id}/tasks`,
        });
      } catch (error) {
        this.$toast(`Не удалось добавит контакт к воронке. ${error.message}`, {
          type: "error",
        });
      }
      this.handleClose();
    },
  },
};
</script>

<style></style>
