<template>
  <v-container>
    <h1 class="mb-5">Поиск контактов</h1>

    <div class="d-md-flex d-sm-block">
      <div class="mr-md-4 mr-xs-0">
        <v-card class="search-container mb-5">
          <v-card-title
            class="text-h6 rm-dialog-title pb-0 d-flex justify-space-between"
            ><span>Фильтры</span
            ><v-btn
              fab
              x-small
              color="#9e9e9e"
              class="ml-2"
              elevation="0"
              :title="isExtraFilters ? 'Скрыть фильтры' : 'Показать фильтры'"
              @click="isExtraFilters = !isExtraFilters"
            >
              <v-icon size="24" color="#fff">{{
                isExtraFilters ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn></v-card-title
          >
          <v-card-text>
            <v-form @submit.prevent="handleSearch">
              <v-text-field
                v-model.trim="filters.search"
                label="Введите почту, ФИО, ID"
                clearable
              />

              <v-expand-transition>
                <div v-show="isExtraFilters">
                  <v-select
                    v-model="filters.sex"
                    :items="sexOptions"
                    label="Пол"
                    item-text="title"
                    placeholder="Выберите пол"
                    item-value="value"
                    clearable
                  />

                  <address-input
                    v-model.trim="filters.city"
                    :initial-value="filters.city"
                    filter="city"
                    label="Город"
                  />

                  <address-input
                    v-model.trim="filters.region_with_type"
                    :initial-value="filters.region_with_type"
                    filter="region_with_type"
                    label="Регион"
                  />

                  <v-autocomplete
                    v-model="filters.tags_ids"
                    :loading="tagLoading"
                    :items="tagsOptions"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="tagSearch"
                    flat
                    clearable
                    :hide-no-data="!noTagResults"
                    label="Тэг"
                    @click:clear="handleTagClear"
                  ></v-autocomplete>

                  <v-subheader class="pa-0 body-1">Возраст</v-subheader>
                  <v-range-slider
                    v-model="range"
                    :max="ageMax"
                    :min="ageMin"
                    :thumb-size="18"
                    thumb-label="always"
                    class="mt-2"
                    color="primary"
                  >
                    <template #append>
                      <v-icon class="age-reset" @click="resetAges">
                        mdi-close
                      </v-icon>
                    </template>
                  </v-range-slider>
                </div>
              </v-expand-transition>

              <v-btn
                color="primary"
                elevation="0"
                :loading="pending"
                :disabled="pending"
                type="submit"
                width="100%"
                class="mb-2"
              >
                Искать
              </v-btn>
              <v-btn
                color="primary"
                outlined
                elevation="0"
                width="100%"
                :disabled="isFiltersEmpty"
                @click="handleClearFilters"
              >
                Сбросить фильтры
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>

      <div class="flex-grow-1">
        <div style="min-height: 4px">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            :active="pending"
          ></v-progress-linear>
        </div>

        <div v-if="!pending && count" class="mb-3">
          <strong
            >Найдено {{ count.toLocaleString("ru") }}
            {{ searchTotalString }}</strong
          >
        </div>

        <v-row class="mb-3">
          <v-col
            v-for="user in list"
            :key="user.id"
            cols="12"
            sm="6"
            md="12"
            lg="6"
          >
            <div class="search-card">
              <user-card
                class="search-card__user"
                :user="user"
                @tagClick="handleTagClick"
              ></user-card>
              <div class="search-card__control px-4 pb-4">
                <v-divider class="mb-4"></v-divider>
                <v-btn
                  color="primary"
                  outlined
                  @click="handleAssignToBoard(user.id)"
                  >Добавить к воронке</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>

        <v-pagination
          v-if="pagesCount > 1"
          :total-visible="7"
          :length="pagesCount"
          :value="page"
          @input="setPage"
        ></v-pagination>

        <div v-if="showClearFiltersMessage">
          Пользователей по выбранным параметрам нет.
          <a
            href="#"
            class="link link--pseudo"
            @click.prevent="handleClearFilters"
            >Сбросить фильтры</a
          >
        </div>

        <div v-if="error" class="error--text">{{ error }}</div>
      </div>
    </div>
    <assign-user-to-board
      v-model="assignToBoardDialog"
      :talent-id="selectedTalentId"
    />
  </v-container>
</template>

<script>
import { apiClient, talentClient } from "@/api";
import UserCard from "@/components/UserCard";
import AddressInput from "@/components/contact/AddressInput";
import { isEqual } from "lodash";
import AssignUserToBoard from "@/components/dialogs/AssignUserToBoard";
import { numCases } from "@/utils";
import debounce from "lodash/debounce";

const AGE_MIN = 1;
const AGE_MAX = 100;
const initialFilters = () => {
  return {
    age_max: AGE_MAX,
    age_min: AGE_MIN,
    city: "",
    region_with_type: "",
    sex: null,
    search: "",
    tags_ids: "",
  };
};
export default {
  name: "Search",
  components: { UserCard, AddressInput, AssignUserToBoard },
  data: () => ({
    filters: initialFilters(),
    sexOptions: [
      { title: "Мужской", value: "m" },
      { title: "Женский", value: "w" },
    ],
    ageMin: AGE_MIN,
    ageMax: AGE_MAX,
    pending: false,
    tagsLimit: 10,
    tagLoading: false,
    tagsOptions: [],
    tagSearch: null,
    noTagResults: false,
    list: [],
    page: 1,
    limit: 10,
    count: 0,
    error: null,
    isExtraFilters: true,
    selectedTalentId: null,
    assignToBoardDialog: false,
  }),
  computed: {
    pagesCount() {
      return Math.ceil(this.count / this.limit);
    },
    searchParams() {
      const { filters } = this;
      return Object.keys(filters).reduce((acc, key) => {
        if (key === "age_max" && filters[key] !== AGE_MAX) {
          acc[key] = filters[key];
        }
        if (key === "age_min" && filters[key] !== AGE_MIN) {
          acc[key] = filters[key];
        } else {
          if (filters[key] && key !== "age_min" && key !== "age_max") {
            acc[key] = filters[key];
          }
        }
        return acc;
      }, {});
    },
    showClearFiltersMessage() {
      return (
        !this.pending &&
        !this.list.length &&
        !this.error &&
        !this.isFiltersEmpty
      );
    },
    isFiltersEmpty() {
      const { filters } = this;
      return !Object.keys(filters).some((key) => {
        if (key === "age_max") {
          return filters[key] !== AGE_MAX;
        } else if (key === "age_min") {
          return filters[key] !== AGE_MIN;
        } else {
          return filters[key];
        }
      });
    },
    range: {
      get() {
        return [this.filters.age_min, this.filters.age_max];
      },
      set(val) {
        this.filters.age_min = val[0];
        this.filters.age_max = val[1];
      },
    },
    searchTotalString() {
      return numCases(["контакт", "контакта", "контактов"], this.count);
    },
    debouncedSearch() {
      return debounce(this.getTagsOptions, 600, {
        leading: false,
        trailing: true,
      });
    },
  },
  watch: {
    "$route.query": {
      handler() {
        this.handleFetchUsers();
      },
    },
    tagSearch(val) {
      if (val?.length >= 3) {
        this.debouncedSearch(val);
      }
    },
  },
  created() {
    this.setQueryToFilters();
    if (!this.isFiltersEmpty) {
      this.getUsers();
    }
    if (this.$route.query.tags_ids) {
      this.getInitialTag();
    }
  },
  methods: {
    async getTags(params) {
      const { data } = await apiClient({
        method: "GET",
        url: "/tags",
        params,
      });
      return data;
    },
    async getInitialTag() {
      const { tags_ids } = this.$route.query;
      try {
        const response = await this.getTags({ ids: tags_ids });
        const option = response?.results?.[0];
        if (option) {
          this.tagsOptions.push(option);
        }
      } catch (error) {
        console.log("error", error);
        //do nothing
      }
    },
    setQueryToFilters() {
      const { query } = this.$route;
      Object.keys(this.filters).forEach((key) => {
        if (key === "age_max" && !query[key]) {
          this.filters[key] = AGE_MAX;
        } else if (key === "age_min" && !query[key]) {
          this.filters[key] = AGE_MIN;
        } else if (key === "tags_ids" && query[key]) {
          this.filters[key] = Number(query[key]);
        } else {
          this.filters[key] = query[key];
        }
      });
      this.page = query.page ? +query.page : 1;
    },
    setPage(page) {
      this.page = page;
      this.setQuery();
    },
    resetAges() {
      this.filters.age_max = this.ageMax;
      this.filters.age_min = this.ageMin;
    },
    handleSearch() {
      this.page = 1;
      this.setQuery();
    },
    handleTagClick(tag) {
      // Если тег уже выбран
      if (this.filters.tags_ids === tag.id) {
        return;
      }
      const f = initialFilters();
      f.tags_ids = tag.id;
      this.tagsOptions.push(tag);
      this.filters = f;
      this.handleSearch();
    },
    handleClearFilters() {
      this.page = 1;
      this.filters = initialFilters();
      this.setQuery();
    },
    async handleFetchUsers() {
      this.setQueryToFilters();
      await this.getUsers();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    setQuery() {
      const { searchParams } = this;
      const query = {
        ...searchParams,
        page: String(this.page),
      };
      if (!isEqual(query, this.$route.query)) {
        this.$router.push({
          query,
        });
      }
    },
    async getCrmContacts(params) {
      const { data } = await apiClient({
        method: "GET",
        url: `/contacts/`,
        params,
      });
      return data;
    },
    async getUsers() {
      const { limit, page, searchParams } = this;
      const offset = (page - 1) * limit;
      this.error = null;
      this.pending = true;
      if (this.filters.search && !isNaN(this.filters.search)) {
        try {
          await talentClient({
            method: "GET",
            url: `/users/${this.filters.search}/`,
          });
          this.$router.push({
            name: "contact",
            params: {
              id: this.filters.search,
            },
          });
          return;
        } catch (error) {
          console.log("error", error);
          // do notihng
        }
      }
      try {
        const params = {
          limit,
          offset,
          ordering: "id",
          ...searchParams,
        };

        const data = await this.getCrmContacts(params);

        this.list = data.results;
        this.count = data.count;
      } catch (error) {
        console.log("error", error);
        this.error = error.message;
      }
      this.pending = false;
    },
    handleAssignToBoard(id) {
      this.selectedTalentId = id;
      this.assignToBoardDialog = true;
    },
    async getTagsOptions() {
      const { tagSearch, tagsLimit } = this;
      try {
        this.tagLoading = true;
        this.noTagResults = false;
        const params = {
          limit: tagsLimit,
          ...(tagSearch && { search: tagSearch }),
        };
        const response = await this.getTags(params);
        this.tagsOptions = response.results;
        this.noTagResults = response.results.length === 0;
      } catch (error) {
        console.log("error", error);
        //do nothing
      }
      this.tagLoading = false;
    },
    handleTagClear() {
      this.tagsOptions = [];
      this.tagSearch = "";
      this.filters.tags_ids = "";
      this.noTagResults = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 250px;
  margin-top: 4px;

  @media screen and (max-width: 961px) {
    width: 100%;
    position: static;
  }
}
.search-card {
  display: flex;
  min-height: 100%;
  background-color: #fff;
  flex-direction: column;
  &__user {
    flex-grow: 1;
  }
  &__controls {
    flex-grow: 0;
  }
}
</style>
