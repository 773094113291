<template>
  <v-autocomplete
    v-model="currentValue"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    item-text="label"
    item-value="value"
    :hide-no-data="!noResults"
    clearable
    autocomplete="new-password"
    :label="label"
    :error-messages="errorMessages"
    @change="handleSelect"
    @onSearch="handleSearch"
    @click:clear="noResults = false"
  ></v-autocomplete>
</template>

<script>
import { apiClient, talentClient } from "@/api";
import debounce from "lodash/debounce";

const geoRestrictions = {
  default: {
    to_bound: { value: "settlement" },
    from_bound: { value: "city" },
    locations: [{ country: "*" }],
  },
  city: {
    to_bound: { value: "city" },
    from_bound: { value: "city" },
    locations: [{ country: "*" }],
  },
  country: {
    to_bound: { value: "country" },
    from_bound: { value: "country" },
    locations: [{ country: "*" }],
  },
  region_with_type: {
    to_bound: { value: "region" },
    from_bound: { value: "region" },
    locations: [{ country: "*" }],
  },
};

export default {
  name: "AddressInput",
  props: {
    value: {},
    errorMessages: {},
    label: {},
    initialValue: {},
    filter: {
      type: String,
      validator(value) {
        return ["city", "country", "region_with_type"].includes(value);
      },
    },
    isCrmGeo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
      loading: false,
      items: [],
      search: "",
      noResults: false,
      maxResult: 6,
    };
  },
  computed: {
    debouncedSearch() {
      return debounce(this.handleSearch, 600, {
        leading: false,
        trailing: true,
      });
    },
    restrictionProperties() {
      const { filter } = this;
      switch (filter) {
        case "city":
        case "country":
        case "region_with_type":
          return geoRestrictions[filter];
        default:
          return geoRestrictions["default"];
      }
    },
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
    search(val) {
      if (val && val.length >= 3) {
        this.debouncedSearch(val);
      }
    },
  },
  mounted() {
    if (this.initialValue) {
      this.items.push(this.initialValue);
    }
  },
  methods: {
    async getTalentGeo(query) {
      const { data } = await talentClient({
        method: "POST",
        url: "/geo/suggest/",
        data: {
          query,
          ...this.restrictionProperties,
        },
      });

      return data.map((n) => {
        switch (this.filter) {
          case "city":
            return {
              label: n.unrestricted_value,
              value: n?.data?.city || n.unrestricted_value,
            };
          case "country":
            return {
              label: n.unrestricted_value,
              value: n.value,
            };
          case "region_with_type":
            return {
              label: n.unrestricted_value,
              value: this.parseRegionString(n.unrestricted_value),
            };
          default:
            return {
              label: n.unrestricted_value,
              value: n.unrestricted_value,
            };
        }
      });
    },
    async getCRMGeo(query) {
      const { data } = await apiClient({
        method: "GET",
        url: `/boards/${this.$route.params.id}/geo/${this.filter}`,
        params: {
          search: query,
        },
      });
      return data || [];
    },
    handleSelect(data) {
      this.$emit("input", data);
    },
    //пока решение такое, желательно с бэка получать сам регион
    parseRegionString(region) {
      if (!region) return "";
      const values = region.split(", ");
      return values[values.length - 1];
    },
    async handleSearch(query) {
      if (this.currentValue === this.search) return;
      this.loading = true;
      this.noResults = false;
      try {
        const items =
          this.isCrmGeo && this.filter
            ? await this.getCRMGeo(query)
            : await this.getTalentGeo(query);

        this.items = items.slice(0, this.maxResult);
        this.noResults = items.length === 0;
      } catch (error) {
        console.log("error", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
