<template>
  <v-sheet class="pa-4 user-card body-1">
    <div class="d-flex align-center mb-4">
      <v-avatar
        color="#dedede"
        class="member-card__avatar"
        darken
        size="40"
        elevation="1"
      >
        <img v-if="avatar" :src="avatar" :alt="fullName" />
        <v-icon v-else color="white" size="24">mdi-account</v-icon>
      </v-avatar>
      <div class="d-flex align-center ml-2">
        <div>
          <router-link
            class="title"
            :to="{ name: 'contact', params: { id: user.id } }"
          >
            {{ fullName || "#" + user.id }}
          </router-link>
          <div class="body-2">#{{ user.id }}</div>
        </div>
      </div>
    </div>
    <option-row v-if="bDay" title="Дата рождения:">{{ bDay }}</option-row>

    <option-row v-if="user.email" title="Почта:">{{ user.email }}</option-row>
    <option-row v-if="user.sex" title="Пол:">{{
      user.sex === "m" ? "Мужской" : "Женский"
    }}</option-row>

    <option-row v-if="user.phone" title="Телефон:">{{ user.phone }}</option-row>
    <option-row v-if="user.address" title="Адрес:">{{
      user.address
    }}</option-row>
    <div v-if="user.tags" class="mt-3">
      <v-chip
        v-for="item in user.tags"
        :key="item.id"
        :color="item.color"
        class="mr-2 mb-2"
        dark
        small
        :title="`Показать всех пользователей с тегом «${item.name}»`"
        @click.stop="$emit('tagClick', item)"
      >
        {{ item.name }}
      </v-chip>
    </div>

    <slot></slot>
  </v-sheet>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";
import { pathJoin } from "@/api/utils";

export default {
  name: "UserCard",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    avatar() {
      const { user } = this;
      if (!user?.avatar) return "";
      if (user.avatar.startsWith("https")) {
        return user.avatar;
      }
      return pathJoin(process.env.VUE_APP_AVATAR_S3, user.avatar);
    },
    fullName() {
      const { user } = this;
      if (!user) return "";
      return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(" ");
    },
    bDay() {
      const { user } = this;
      if (!user || !user.birthday) return null;

      return dayjs(user.birthday, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  position: relative;
  min-height: 200px;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: inherit !important;
  transition: color 0.3s;
  max-width: 600px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: var(--v-anchor-base) !important;
  }
}
</style>
